/*********************
NAVIGATION
*********************/

// Main Menu
.main-menu{
    margin-right: 60px;
    .menu{
        li{
            display: inline-block;
            padding: 0 0.75em;
            &.active{
                > a{
                    background: transparent;
                    font-weight: bold;
                    border-bottom: 3px solid $blue-dark;
                    color: $blue-dark;

                }
            }
            a{
                font-weight: 700;
                font-size: rem-calc(13);
                // letter-spacing: .05em;
                font-family: $ff-salesforce-sans;
                // text-transform: uppercase;
                transform: translateY(5px);
                color: $blue-darker;
                padding: .25em 0;
                position: relative;
                border-bottom: 3px solid transparent;
                @include transition(.25s linear all);
                &:hover{
                    color: $blue;
                }
                .status{
                    position: absolute;
                    right: -15px;
                    top: -5px;
                }
            }
        }
    }
}

// Status
.status{
    background: $red;
    color: $white;
    width: 14px;
    height: 14px;
    line-height: 14px;
    font-size: 12px;
    text-align: center;
    display: block;
    border-radius: 100%;
}

.menu-button{

}
.nav-divider{
    .divider{
    transform: translateY(3px);
    }
}

// Burger Menu
.open-menu {
    @include transform(rotate(0deg));
    @include transition(0.5s ease-in-out);
    width: 50px;
    height: 50px;
    //background: yellow;
    position: relative;
    cursor: pointer;
    display: block;
    border-bottom: none;
    &:focus,
    &:hover,
    &:hover:focus,
    &:active{
        border-bottom: none;
    }
    .mobile-menu &{
        position: absolute;
        right: 5px;
        top: 10px;
        z-index: 999999;
        @include breakpoint(medium){
            right: 15px;
            top: 15px;
        }
        @include breakpoint(1600px){
            right: 50%;
            margin-right: -785px;
        }
        span{
            background-color: $white;
        }
        &:hover{
            span{
                background-color: $grey;
            }
        }

    }

    &:hover{
        span {
            background: $grey;
        }
    }

    span {
        display: block;
        position: absolute;
        height: 2px;
        width: 40%;
        left: 30%;
        background: $grey-dark;
        opacity: 1;
        @include transform(rotate(0deg));
        @include transition(0.25s ease-in-out);
        -webkit-transform-origin: left center;
        transform-origin: left center;
        &:nth-child(1) {
            top: 18px;
        }
        &:nth-child(2) {
            top: 25px;
        }
        &:nth-child(3) {
            top: 32px;
        }
    }

    &.active{
        span{
            &:nth-child(1) {
                @include transform(rotate(45deg));
                top: 18px;
                left: 37.5%;
            }
            &:nth-child(2) {
                width: 0%;
                opacity: 0;
            }
            &:nth-child(3) {
                @include transform(rotate(-45deg));
                top: 32px;
                left: 37.5%;
            }
        }
    }
}

// Mobile Menu
.mobile-menu {
    position: fixed;
    background: $blue-dark;
    top: 0;
    left: 0;
    width: 100%;
    height: 0%;
    opacity: 0;
    visibility: hidden;
    @include transition(all .35s);
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 99999;
    &.open {
        opacity: 1;
        visibility: visible;
        height: 100%;
        li {
            @include animation(fadeInRight .5s ease forwards);
            @include animation-delay(.35s);
            &:nth-of-type(2) {
                @include animation-delay(.4s);
            }
            &:nth-of-type(3) {
                @include animation-delay(.45s);
            }
            &:nth-of-type(4) {
                @include animation-delay(.5s);
            }
            &:nth-of-type(5) {
                @include animation-delay(.55s);
            }
            &:nth-of-type(6) {
                @include animation-delay(.6s);
            }
        }
    }
    nav {
        position: relative;
        height: 90%;
        top: 50%;
        @include transform(translateY(-50%));
        text-align: center;
        padding-top: 50px;
    }
    ul {
        list-style: none;
        padding: 0;
        margin: 0 auto;
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        li {
            display: block;
            position: relative;
            opacity: 1;
            font-size: 25px;
            line-height: 1;
            @include breakpoint(medium){
                font-size: 40px;
            }
            a{
                display: inline-block;
                position: relative;
                color: $white;
                border-bottom: none;
                text-decoration: none;
                background: none !important;
                overflow: hidden;
                // font-family: $font-sans;
                letter-spacing: .05em;
                @include transition(.25s linear all);
                padding: .5rem 1rem;
                &:focus{
                    color: $grey;
                }
                &:hover{
                    color: $grey;
                    border-color: $grey;
                }
                &:hover,
                &:focus,
                &:active{
                    &:after{
                        width: 100%;
                    }
                }
            }
            &.active {
                a{
                    color:$grey;
                }
            }
        }
    }
}