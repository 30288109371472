@import "./styles/fonts";
@import "./styles/variables";
@import "./styles/mixin";
@import "./styles/colours";
@import "./styles/foundation";
@import "./styles/global";
@import "./styles/backgrounds";
//# ||****\ GENERAL
.no-margin{
 margin:0;
}
.animate-opacity {
  transition: all 1s;
}
.invisible {
  opacity: 0;
}
.hidden {
  display: none !important;
}
.white-text {
  color: #fff;
}
.white-bg{
  background-color: #fff!important;
  background: #fff!important;
}
.display-flex {
  display: flex;
}
.name-script {
  margin-right: 5px;
  text-transform: capitalize;
}
.capitalize {
  text-transform: capitalize;
}
.no-underline{
  text-decoration: none;
}
.flex-auto{
  flex:auto;
}
.padding-y-100 {
  padding-top: 100px;
  padding-bottom: 100px;
}
.pad-bottom-100 {
  padding-bottom: 100px;
}
.pad-10{
  padding: 10px;
}
.margin-top-15 {
  margin-top: 15px;
}
.margin-top-30 {
  margin-top: 30px;
}
.margin-bottom-15 {
  margin-bottom: 15px;
}
//# ||****//////////
.main-button {
  background-color: #0176d3 !important;
  &:hover {
    background-color: #014486 !important;
  }
}
//# ||****\ MODAL
.ReactModal__Body--open,
.ReactModal__Html--open {
  overflow-y: hidden;
}
// .form-modal {
//   position: absolute;
//   top: 50%;
//   transform: translate(-50%, -50%);
//   left:50%;
// }
.ReactModal__Content {
  z-index: 999;
}
.form-modal {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  width: 100%;

  @include respond-to("medium") {
    width: 80%;
  }

  // .form-modal {
  //   position: fixed;
  //   left: 0;
  //   top: 0;
  //   right: 0;
  //   bottom: 0;
  //   text-align: center;
  //   padding-top: 20px;
  // }
}
  .Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(3, 46, 97, 0.95);
    z-index: 999;
  }
