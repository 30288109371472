/*********************
BUTTONS
*********************/

.big-link{
    border: 2px solid #0176D3;
    border-bottom: 2px solid #0176D3!important;
    border-radius: 4px;
    padding: 0.5em 2em!important;
    background: $white;
    &:hover{
        background: #0176D3!important;
        color: $white!important;
    } 
    color: #0176D3!important;
    transform: none!important;
    text-decoration: none;
}
.button{
    font-family: $ff-salesforce-sans;
    text-transform: uppercase;
    font-weight: 700;
    border-radius: 5px;
    padding: 0.875em 2.25em;
    outline: none!important;
    font-size: 1em;
    text-decoration: none;
    &:focus{
        outline: none;
    }
    &.wide{
        min-width: 250px;
        width: 100%;
        @include breakpoint(medium){
            width: auto;
        }
    }
    &.full{
        width: 100%;
    }
    &.large{
        padding: 1rem 2.25rem;
        font-size: 1.25em;
    }
    &.btn-outline-blue-dark{
        border: 2px solid $blue-dark;
        background: $white;
        color: $blue-dark;
        &:hover{
            background: $blue-dark;
            color: $white;
        }
    }
    &.btn-outline-blue{
        border: 2px solid $blue-dark;
        background: $white;
        color: $blue-dark;
        &:hover{
            background: $blue-dark;
            color: $white;
        }
    }
    &.btn-outline-white{
        border: 2px solid $white;
        background: none;
        color: $white;
        &:hover{
            background: $white;
            color: $blue-dark
        }
    }
    &.btn-red{
        background: $red;
        &:hover{
            background: darken($red, 10%);
        }
    }
    &.btn-yellow{
        background: $yellow;
        &:hover{
            background: darken($yellow, 10%);
        }
    }
    &.btn-blue{
        background: $blue-light;
        &:hover{
            background: darken($blue-light, 10%);
        }
    }
    &.btn-blue-dark{
        border: 2px solid $blue-dark;
        background: $blue-dark;
        &:hover{
            border: 2px solid $blue-dark;
            background: $white;
            color: $blue-dark
        }
    }
}
