// Colors
$black      : #222222;
$blue-dark  : #0037b1;
$blue-darker : #032D60;
$blue       : #215CA0;
$blue-light : #00A1E0;
$cyan-dark  : #8CD3F8;
$cyan       : #C2E8FF;
$cyan-light : #E3F7FF;
$white      : #FFF;
$green      : #00B2A9;
$yellow     : #EBBC48;
$red        : #FD7456;
$purple     : #7D52C2;
$brown      : #784834;
$grey       : #BEBEBE;
$grey-light : #F7F7F7;
$grey-dark  : #7E7E7E;
$grey-darker: #222;

$c-white: #FFF;
$c-black: #000;
$c-green-vogue: #032D60;
$c-tory-blue: #0B5CAB;
$c-lochmara: #0176D3;
$c-malibu: #7BD1F8;
$c-lightning-yellow: #FDC516;
$c-carnation-pink: #FF92BE;
$c-solitude: #EAF5FE;
$c-scarpa-flow: #59575C;
$c-corn: #E4A201;
$c-niagara: #06A59A;
$c-brown-derby: #4A2413;
$c-banana-mania: #FBE8A3;
$c-dove-gray: #666;
$c-gallery: #EEE;

// Fonts
$ff-trailhead: 'Trailhead', serif;
$ff-avant-garde: 'Avant Garde', sans-serif;
$ff-salesforce-sans: 'Salesforce Sans', sans-serif;

$fw-light: 300;
$fw-regular: 400;
$fw-medium: 500;
$fw-demi: 600;
$fw-semibold: 600;
$fw-bold: 700;