/*********************
HEADER
*********************/

.header{
    width: 100%;
    z-index: 999;
    position: fixed;
    background: rgba($white, 1);
    box-shadow: 0 0 10px rgba($black, .15);
    @include breakpoint(medium){
        //padding-top: 20px;
        //padding-bottom: 20px;
    }
}

.logo-header{
    img{
        width: 55px;
        margin: 10px;
        height: auto;
        @include transition (all .3s linear);

    }
}
