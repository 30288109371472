/*********************
Section
*********************/
.dark{
  .section-title, .section-subtitle{em{color: $c-malibu}}
}
.section {
  position: relative;
  overflow: hidden;

  &-tag {
    display: inline-block;
    min-width: 80px;
    margin-bottom: 20px;
    padding: 9px;
    font: {
      size: 12px;
      weight: $fw-bold;
    }
    letter-spacing: 0.1em;
    line-height: 1;
    background-color: $c-white;
    border: $c-scarpa-flow solid 1px;
    border-radius: 16px;
    color: $c-scarpa-flow;
  }

  &-title,
  &-subtitle {
    margin: 0;

    span {
      display: block;
    }

    em,
    strong {
      color: $c-lochmara;
    }

    em {
      font-style: normal;
    }

    strong {
      font: {
        family: $ff-trailhead;
        weight: $fw-bold;
      }
    }
  }

  &-title {
    font: {
      size: 32px;
      weight: $fw-bold;
    }
    line-height: 1.25;

    @include respond-to('xxlarge') {
      font-size: 40px;
    }

    &.large {
      font-size: 36px;
  
      @include respond-to('xxlarge') {
        font-size: 48px;
      }
    }
  }

  &-subtitle,
  &-description {
    margin: {
      top: 20px;
      right: auto;
      left: auto;
    }
  }

  &-subtitle {
    font: {
      size: 18px;
      weight: $fw-bold;
    }
    line-height: 1.5;

    @include respond-to('xlarge') {
      font-size: 20px;
    }
  }
  &-description {
    font: {
      size: 16px;
      weight: $fw-regular;
    }
    line-height: 1.5;

    @include respond-to('medium') {
      width: 85%;
      max-width: 720px;
    }

    @include respond-to('xxlarge') {
      font-size: 18px;
      max-width: 850px;
    }
  }

  &-container {
    padding: {
      right: 15px;
      left: 15px;
    }

    @include respond-to('xlarge') {
      padding: {
        right: 0;
        left: 0;
      }
    }
  }

  &-gap {
    &-1,
    &-2 {
      padding-bottom: 150px;
    }

    &-1 {
      padding-top: 130px;
    }

    &-2, &-3 {
      padding-top: 50px;
    }
  }

  &-bg {
    &-1,
    &-2,
    &-gi,
    &-g1,
    &-3,
    &-0 {
      color: $c-green-vogue;
    }

    &-1,
    &-3 {
      background-color: $c-white;
    }
    &-0{background-color: #fff!important;}
&-g {
  background: $c-solitude;
  background: linear-gradient(180deg, rgba(144,208,254,1) 28%, rgba(255,255,255,0) 100%);
  overflow: visible;      
}

    &-gi {
      background: $c-solitude;
      background: linear-gradient(180deg, rgba(144,208,254,1) 28%, rgba(255,255,255,0) 100%);
      overflow: visible;      
    }
    &-2 {
      background: white;
      // background: linear-gradient(0deg, rgba(234,245,254,1) 28%, rgba(255,255,255,0) 100%);
      overflow: visible;      
    }

    &-2,
    &-2i,
    &-3 {
      background: {
        position: center bottom;
        repeat: no-repeat;
        size: cover;
      }
    }
  }
}
